import { useEffect, useState } from "react";
import "./App.css";

interface BinDataResponse {
  nextCollectionDate: string
  binsOnNextDate: string[]
  nextRedBinDate: string
  nextGreenBinDate: string
  nextYellowBinDate: string
  nextPurpleBinDate: string
}

function App() {

  const [binData, setBinData] = useState<BinDataResponse>()
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wedneday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    fetch("https://bincollection.azurewebsites.net/api/binCollection").then((response) => {
      response.json().then((json) => {
        setBinData(json)
      })
    })
  }, [])

  let nextCollection = undefined
  let binsText = ""
  let messageText = "Fetching from council ..."
  let nextDatesText = ""
  let redBinDateText = ""
  let greenBinDateText = ""
  let yellowBinDateText = ""
  let purpleBinDateText = ""

  if (binData) {
    nextCollection = new Date(binData?.nextCollectionDate)
    binData.binsOnNextDate.forEach(item => {
      const isLastItem = binData.binsOnNextDate[binData.binsOnNextDate.length - 1] === item
      binsText += binsText === "" ? item : isLastItem ? ` and ${item}` : `, ${item}`
    });
    messageText = `On ${dayNames[nextCollection?.getDay()]} place the ${binsText.trim()} bins out.`
    nextDatesText = `Red:${binData.nextRedBinDate} Green:${binData.nextGreenBinDate} Yellow:${binData.nextYellowBinDate} Purple:${binData.nextPurpleBinDate}`
    redBinDateText = new Date(binData.nextRedBinDate).toLocaleDateString()
    greenBinDateText = new Date(binData.nextGreenBinDate).toLocaleDateString()
    yellowBinDateText = new Date(binData.nextYellowBinDate).toLocaleDateString()
    purpleBinDateText = new Date(binData.nextPurpleBinDate).toLocaleDateString()
  }

  return (
    <div className="App">
      <div className="mainMessage">{messageText}</div>
      {binData && (
        <>
          {binData.binsOnNextDate.includes("red") && <div className="redBox"></div>}
          {binData.binsOnNextDate.includes("green") && <div className="greenBox"></div>}
          {binData.binsOnNextDate.includes("yellow") && <div className="yellowBox"></div>}
          {binData.binsOnNextDate.includes("purple") && <div className="purpleBox"></div>}
          <div className="binsDates"><span className="redDot"></span>{redBinDateText}<span className="greenDot"></span>{greenBinDateText}<span className="yellowDot"></span>{yellowBinDateText}<span className="purpleDot"></span>{purpleBinDateText}</div>
        </>
      )}
    </div>
  );
}

export default App;
